<!--列表页-->
<template>
  <div class="brandOperationDiv">
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col class="rowSpace">
        <!--左侧菜单栏-->
        <div class="leftDiv">
          <left-menu :menu-list="leftMenu" @menuActive="clickMenu"></left-menu>
        </div>
        <!--右侧区域-->
        <div class="rightDiv">
          <div class="rulesDiv">
            <div class="rulesTitle">
              {{menuName}}
            </div>
            <div class="line-class"></div>
            <!--新闻列表-->
            <div class="notice-table contentMinHeight">
              <div class="notice-item" v-for="(item, index) in dataList" :key="index" @click="toDetail(item)">
                <div>
                  <div class="notice-item-before"/>
                  <div class="notice-item-title" :title="item.noticeTitle">{{ item.contentTitle }}</div>
                </div>
                <div class="notice-date">{{ item.createTime }}</div>
              </div>
            </div>
            <!--分页-->
            <div class="page">
              <el-pagination
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :current-page.sync="page"
                @current-change="pageChange"
                @size-change="handleSizeChange"
                @prev-click="prevClick"
                @next-click="nextClick"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import topPathList from '@/components/topPathList'
import leftMenu from '@/components/leftMenu'
import axios from 'axios'

export default {
  name: "index",
  components: { topPathList, leftMenu },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '',
          path: '/committee/index'
        },
      ],
      leftMenu: [],
      nowMenu: '1',
      menuName: '',
      title: '',
      dataList: [],
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 10,
      contentType: '',
      leftList: [],
    }
  },
  mounted () {
    this.getLeftDict()
    this.title = sessionStorage.getItem('committeeContentTitle');
    this.contentType = sessionStorage.getItem('committeeContentType');
    this.pathObj[1].name = this.title;
    this.getList();
  },
  methods: {
    //获取左侧字典值
    getLeftDict() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/dicFront/selectListByTypeName" + "?typeName=INT_PRO_COMMIT",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.leftList = res.data.data
          this.leftList.forEach(item => {
            this.leftMenu.push({ name: item.label, value: item.value });
          })
          this.menuName = this.leftMenu[0].name

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },


    clickMenu({ menu, index }) {
      this.menuName = menu.name;
      //this.nowMenu = index
      this.nowMenu = menu.value;
      this.page = 1
      this.getList()
    },
    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },
    getList() {
      let params = {
        current: this.page,
        size: this.pageSize,
        typeId: this.nowMenu, // 对应字典值
        contentType: this.contentType,
      }
      this.dataList = [];
      //this.tableTotal = 0;
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/contentType/querySpecialCommittee",
        data: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data;
          this.dataList = data.data;
          /*日期显示年月日*/
          for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].createTime = this.dataList[i].createTime.substring(0,10)
          }
          this.tableTotal = data.count;
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
    toDetail(val) {
      let typeId = this.nowMenu;
      this.$router.push({
        path: '/committee/committeePreview',
        query: {
          value: JSON.stringify( val ),
          pathObj: JSON.stringify( this.pathObj ),
          typeId: typeId.toString(),
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .brandOperationDiv {
    background-color: #f8f9fa !important;
    text-align: center;
  }
  .rowSpace {
    display: flex;
  }
  .leftDiv {
    width: 15%;
  }
  .rightDiv {
    width: 82%;
    background: white;
    border-radius: 5px;
    margin-bottom: 40px;
  }
  .rulesDiv {
    transition: all .2s ease;
    background: #FFFFFF;
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 23px 24px;

    .rulesTitle {
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #1A5BC5;
      line-height: 30px;
      text-align: left;
    }

    .line-class {
      margin-top: 20px;
      width: 910px;
      border: 1px solid #EBEFF6;
    }

    .notice-table {
      width: 910px;
      .notice-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        height: 50px;

        .notice-item-title {
          position: relative;
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
          width: 432px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        }

        .notice-item-before {
          position: relative;
          margin: 16px 10px 0px 0px;
          height: 7px;
          width: 7px;
          background: #bacff0;
          border-radius: 50%;
          content: "";
          float: left;
        }

        .other {
          width: 164px;
          color: #F0A42D;
        }

        .notice-date {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #9FACB9;
          line-height: 40px;
        }
      }

      .notice-item:hover {
        cursor: pointer;

        .notice-item-before {
          position: relative;
          margin: 16px 10px 0px 0px;
          height: 7px;
          width: 7px;
          background: #528ffa;
          border-radius: 50%;
          content: "";
          float: left;
        }

        .notice-item-title {
          color: #528ffa;
        }

      }

    }

    .page {
      text-align: right;
    }


  }
  @media screen and (max-width: 1366px) {
    .brandOperationDiv {
      background-color: #f8f9fa !important;
      text-align: center;
    }
    .rowSpace {
      display: flex;
    }
    .leftDiv {
      width: 15%;
    }
    .rightDiv {
      width: 82%;
      background: white;
      border-radius: 5px;
      margin-bottom: 40px;
    }
  }
</style>
